export const mockGirls: any[] = [
    {
        bio: '',
        firstName: 'AK GIRLS',
        id: '1',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F1g.jpg?alt=media&token=ba36a383-b70c-495c-b7d1-7975b39453b0',
        username: 'akgirlscz',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Vivi',
        id: '2',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F2g.jpg?alt=media&token=25f0e705-a823-4e0c-ace4-33817e037626',
        username: 'vivien',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Alice ',
        id: '3',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F3g.jpg?alt=media&token=65fa567c-6986-4cea-9dfe-b7bafe2a066d',
        username: 'alice',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Katka ',
        id: '4',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F4g.jpg?alt=media&token=23db0879-de90-4301-8707-6a59e47f5d08',
        username: 'katerina',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Nela ',
        id: '5',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F5g.jpg?alt=media&token=33b21165-8092-4da2-a482-5bc30dd533cf',
        username: 'cislojedna',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Wendy ',
        id: '6',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F6g.jpg?alt=media&token=39e738fa-6d77-4e34-96a5-86f0297d02d6',
        username: 'wendy',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    },
    {
        bio: '',
        firstName: 'Daisy ',
        id: '7',
        image: 'https://firebasestorage.googleapis.com/v0/b/akgirls.appspot.com/o/girls%2Fdemo_girls%2F7g.jpg?alt=media&token=f2637930-cc61-4f24-8db2-f11da9311df4',
        username: 'jsemdaisy',
        messagePrice: 10,
        price: 100,
        age: 25,
        city: 'New York',
        coins: 100,
        createdAt: new Date().toString(),
        email: '',
        hasCreatorProfile: 1,
        hashPassword: '',
        role: 'creator',
        phoneNumber: '123456789',
        porfolio: '',
        verifiedCreator: 1,
        pushNotificationsData: {},
        pushNotificationsEnabled: false
    }
];
